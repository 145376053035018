import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import PageNav from '../components/layout/PageNav';


const OurColourPalette = ({ data={} }) => {

  const { modules, title, description=false, id } = mapPageData(data.page)

  const [modulesToRender, heroModule ] = useModules(modules, { getHeroModule: true })

  return (
  <>
  <SEO title={title} description={description}/>
  <Layout 
    staticMenuLayout={true}
    homeLink={true}
    staticMenu={true}
    heroModule={heroModule}
    className="static-page">
      {/* Content goes here */}
      { modulesToRender.map( ({Module, id}) =>  <Module key={id} /> )}
      <PageNav 
        prev={{  text: "The Knorr Logo", linkTo: "/theknorrlogo"}} 
        next={{  text: "Photography", linkTo: "/photography"}}
      />
  </Layout>
  </>
)}

export default OurColourPalette


export const OurColourPaletteQuery = graphql`
  query OurColourPaletteQuery {
  page: contentfulPage(pageId: {eq: "colour-palette"}) {
      ...PageFragment
    }
  }
`
